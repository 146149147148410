import React from 'react'

const BlogsScreen = () => {
  return (
    <div className='blogs-screen'>
      <p>Blogs</p>
      <p>Coming Soon...</p>
    </div>
  )
}

export default BlogsScreen
